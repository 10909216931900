<template>
  <Box>
  
    <ErrorRow :error="error" /> 
    <SuccessRow :message="message" /> 
    
    <LoadingRow :showBorder="true" v-if="isLoading">{{loadingMessage}}</LoadingRow>
    <Row v-else>
      <Column>
        <Box>
      
          <Row :showBorder="true">
            <Column :width="11">
            </Column>
            <Column :width="2">
              <Button v-on:click="refresh()">{{ MC.CheckList.RefreshButton.value() }}</Button>
            </Column>
            <Column :width="2">
              <Button :go="true" v-on:click="newCheckList()">{{ MC.CheckList.AddButton.value() }}</Button>
            </Column>
          </Row>

          <TitleRow>
            <Column>
              <Box>
            
                <Row>
                  <Column :width="3">
                    <Value fontColour="light" fontSize="title">Name</Value>
                  </Column>
                  <Column :width="9">
                    <Value fontColour="light" fontSize="title">Description</Value>
                  </Column>
                  <Column :width="3">
                    <Value fontColour="light" fontSize="title">Questions</Value>
                  </Column>
                </Row>
                    
              </Box>
            </Column>
          </TitleRow>
          
          <Row v-for="(checklist, questionIndex) in checklists" :key="questionIndex"
              :class="Strings.isEven(questionIndex) ? 'rowEven' : 'rowOdd'" >
            <Column>
              <Box>
            
                <Row>
                  <Column :width="3">
                    <Value>{{ checklist.name() }} </Value>
                  </Column>
                  <Column :width="9">
                    <Value>{{ checklist.description() }} </Value>
                  </Column>
                  <Column :width="3">
                    <Value>{{ checklist.items().size() }} </Value>
                  </Column>
                </Row>
                
                <Row>
                  <Column :width="1">
                    <!--  
                    <Button v-on:click="detailsCheckList(checklist.id())">{{ MC.CheckList.DetailsButton.value() }}</Button>
                    -->
                  </Column>
                  <Column :width="13" />
                  <Column :width="1">
                    <Button v-on:click="editCheckList(checklist.id())">{{ MC.CheckList.EditButton.value() }}</Button>
                  </Column>
                </Row>
                    
              </Box>
            </Column>
          </Row>
            
        </Box>
      </Column>
    </Row>
  </Box>
</template>

<script>

import { mapGetters, mapActions } from 'vuex';

import ConstUtils   from '@/utils/ConstUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import StringUtils  from '@/utils/StringUtils.js';
import ErrorRow    from '@/components/row/ErrorRow.vue';
import SuccessRow  from "@/components/row/SuccessRow.vue";

import MC from "@/domain/session/MC.js";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import Value from "@/portals/shared/library/value/Value.vue";

import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";  

import CheckList from '@/domain/model/checklist/CheckList.js';
import ChecklistListFilter from '@/domain/model/checklist/ChecklistListFilter.js';
import CheckListMap from '@/domain/model/checklist/CheckListMap.js';


export default {
  name: "checklist-list-view",
  components: {
    Box, Row, Column,
    TitleRow, 
    Button, Value,
    LoadingRow,
    ErrorRow, SuccessRow,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data() {
    return {
      error: null,
      message: null,
      
      isLoading: true,
      loadingMessage: null,
      
      MC: new MC(),
      Strings: StringUtils,
      Contents: ContentUtils,
      Consts: ConstUtils,
      
      checklistMap: null,
      checklists: [],
      CheckList: CheckList,
      pageSize: 10,
      pageNumber: 0,
      
      showSessionTimeoutDialog: false,
    };
  },
  computed: {
    ...mapGetters([
                   'auth_connected',
                   'auth_socket_status',
                   'signin_event',
                   'auth_client',
                   'domain',
                   ]),
    itemId: function() {
      return this.$route.params['id'];
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
                   'AuthStorage_updateCompany',
                   'addListenerAction',
                   'removeListenerAction',
                   'updateDomain',
                   ]),

    start: function() {
      this.isLoading = false;
      this.loadingMessage = this.MC.Status.Done.value();
      this.loadChecklists();
    },
    callback: function(msg) {
      this.loadingMessage = msg;
    },
    refresh: function() {
      this.loadChecklists();
    },
    loadChecklists: function() {
      if (this.isLoading) {
        return;
      }
      if (!this.domain) {
        return;
      }
      this.checklists = [];
      this.isLoading = true;
      this.error = null;
      this.loadingMessage = this.MC.CheckList.Status.Loading.value();
      this.pageSize = 10;
      this.pageNumber = 0;
      var filter = new ChecklistListFilter();
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .done();

      filter
        .withCompany(this.domain.session().company().id())
        .done();

      var event = this.domain.events().checklists().list(filter);
      event.send(this.loadChecklistsListener);
    },
    loadChecklistsListener: function(response) {
      if (response.error()) {
        this.error = response.error();
      } else {
        var payload = response.payload();
        var values = payload.valuesFor(CheckListMap.MODEL_NAME);
        this.checklistMap = new CheckListMap(this.domain, values);
        this.checklists = this.checklistMap.sortByName();
      }
      this.isLoading = false;
    },
    newCheckList: function() {
      this.$router.push({
        name: ConstUtils.ROUTES.CHECKLIST.ADD,
      });
    },
    editCheckList: function(checklistId) {
      var params = {
          id: checklistId,
        };
        
      this.$router.push({
        name: ConstUtils.ROUTES.CHECKLIST.EDIT,
        params: params,
      });
    },
    detailsCheckList: function(checklistId) {
      var params = {
          id: checklistId,
        };
        
      this.$router.push({
        name: ConstUtils.ROUTES.CHECKLIST.DETAILS,
        params: params,
      });
    },

  },
}
</script>